@import 'variables';

$primary: $brand;

@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';

@import 'forms.scss';
@import 'tabs.scss';
@import 'checkboxes.scss';
@import 'progress.scss';
@import 'file-input.scss';

.btn {
    text-transform: uppercase;
}

.btn-secondary {
    color: #56124b;
    background-color: #d9ebf9;
    border-color: #d9ebf9;
}

.btn-secondary:hover {
    color: #56124b;
    background-color: #b5d3ea;
    border-color: #b5d3ea;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.btn-success {
    color: #fff !important;
    background-color: #5cb85c !important;
    border-color: #5cb85c !important;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    background: #FDF0E0;
    font-family: 'Open Sans', 'Arial', sans-serif;
    margin-bottom: 60px;
}

header {
    background: $brand;
    margin-bottom: 80px;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(86, 18, 75, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler {
    background: white;
    color: red;
}

.navbar-toggler:hover {
    background-color: #e57ba3;
}

.navbar-nav a {
    color: white;
    font-weight: bold;
}

main {
    margin-bottom: 100px;
    margin-top: 50px;
}

footer {
    position: absolute;
    bottom: 0;
    background: $brand;
    padding: 16px 0;
    width: 100%;
    color: white;
    font-size: 85%;
}

header .logo {
    margin-bottom: -80px;
}

h1, h2 {
    color: $brand;
}

h2 {
    font-size: 18px;
    font-weight: 700;
}

h3 {
    font-size: 18px;
    font-style: italic;
    color: #747474;
}

h4 {
    font-size: 16px;
    color: #052137;
}

/* Stage */
.stage-header h2 + .description {
    font-style: italic;
    color: #56124b;
}

.stage-group h3 + .description {
    font-size: 16px;
    font-style: italic;
    color: #747474;
}

p.mandatory {
    font-size: 14px;
    font-style: italic;
    color: #56124b;
}

.stage-group h3 + .description {
    font-size: 16px;
    font-style: italic;
    color: #747474;
}

.stage-sidebar-groups {
    background: #fff;
}

.stage-group-list {
    list-style: none;
}

ul.stage-group-list li, ul.stage-group-list li a {
    color: #747474;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
}

ul.stage-group-list li.active,
ul.stage-group-list li.active a,
ul.stage-group-list li.active:hover a {
    color: #e57ba3;
}

p.footnote {
    font-size: 13px;
    font-style: italic;
    line-height: 1.3;
    color: #747474;
}

.custom-file-input ~ .custom-file-label::after {
    content: "Button Text";
    text-transform: uppercase;
}

.message {
    position: relative;
    margin-left: 25px;
}

.message:before {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #56124b;
    top: 6px;
    left: -25px;
}

.message.read:before {
    background: #986a87;
}

.message.read h3 a {
    color: #986a87;
}

.message.read p {
    color: #90887f;
}

.divider-bullet:after {
    content: "\2022";
    display: inline-block;
    position: relative;
    margin: 0px 2px;
}

.main-menu a {
    color: white;
}

.green {
    color: #2dc96f;
}

@media only screen and (max-width: 991px) {
	.logo img {
		max-width: 75%;
	}

	header .nav-item {
		text-align: right;
	}
}

header .nav-item a {
	color: $pink;
	font-weight: 700;
}

header .nav-item a:hover {
	color: $lightblue;
}

header .nav-item.active a {
	color: #FFF;
}