.progress {
    height: 8px;
    margin: 0;
    overflow: hidden;
    background-color: #d9ebf9;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #d9ebf9;
    background-color: $brand;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}
.progress + .progress-percentage {
    text-align: right;
    font-size: 16px;
    font-style: italic;
    color: #747474;
    margin-top: 4px;
}
