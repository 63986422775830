.nav-tabs {
    border-bottom-color: #b5d3ea;
}

.nav-tabs .nav-link {
    color: #052137;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background: #d9ebf9;
    border-width: 1px;
    border-style: solid;
    border-bottom-color: transparent;
    border-top-color: #b5d3ea;
    border-left-color: #b5d3ea;
    border-right-color: #b5d3ea;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #d9ebf9;
    background-color: #d9ebf9;
    border-bottom-color: #b5d3ea;
}

.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
    border-color: inherit;
    border-width: 1px;
    border-style: solid;
    border-bottom-color: transparent;
    border-top-color: #b5d3ea;
    border-left-color: #b5d3ea;
    border-right-color: #b5d3ea;
}

