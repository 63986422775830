.custom-file-input--:lang(en) ~ .custom-file-label--::after {
    content: "Select File";
}

.custom-file-input--.selected,
.custom-file-input--.selected:focus,
.custom-file-input--:focus {
    z-index:0;
}

.custom-file-input--.selected:lang(en) ~ .custom-file-label--::after {
    content: "Change";
}

.custom-file-label--, .custom-file-label--:after {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
}

.custom-file-input-- {
    z-index:0 !important;
}

.custom-file-input--,
.custom-file-label-- {
    border-radius: 0.25em !important;
}

.custom-file-label-- {
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    font-weight: 400;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-label--::after {
    background-color: $brand;
    color: white;
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}

input[type=file].custom-file-input--::-webkit-file-upload-button {
    display: none;
}

input[type=file].custom-file-input--::file-selector-button {
    display: none;
}

input[type=file].custom-file-input--+.custom-file-label--:after {
    background-color: $brand;
    color: white;
    overflow: hidden;
    text-transform: uppercase;
    cursor: pointer;
    margin-bottom: 0;
}

.custom-file-label {
    overflow: hidden;
}

input[type="date"].custom-date + .input-group-append {
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 10;
    cursor: pointer;
}

.btn {
    margin-right: 3px;
}

label {
    margin-bottom: 0.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.input-group-text {
    height: 100%;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

a.small,
.modal-overlay {
    cursor: pointer;
}

.input-group-append,
input[type="date"].custom-date {
    border-top-right-radius: 0.25em !important;
    border-bottom-right-radius: 0.25em !important;
}

.input-group-append > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}