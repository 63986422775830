.checkbox-description {
    display: block;
    margin: 4px 0 0 24px;
}
input[type=checkbox]:not(.old),
input[type=radio   ]:not(.old) {
    width: 0;
    margin: 0;
    padding: 0;
    font-size: 16px;
    opacity: 0;
    position: absolute;
}
input[type=checkbox]:not(.old) + label,
input[type=radio   ]:not(.old) + label {
    display: inline-block;
    margin-left: 24px;
    line-height: 1.4;
}
input[type=checkbox]:not(.old) + label > span:first-child,
input[type=radio   ]:not(.old) + label > span:first-child {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0 .5em .2em 0;
    border: .0625em solid #b5d3ea;
    width: 15px;
    height: 15px;
    margin: 0 8px 3px -24px;
    border: 1px solid #b5d3ea;
    border-radius: 0;
    background: #fff;
    vertical-align: bottom;
}
input[type=checkbox]:not(.old):checked + label > span:first-child,
input[type=radio   ]:not(.old):checked + label > span:first-child {
    background: #d9ebf9;
}
input[type=checkbox]:not(.old):checked + label > span:first-child:before {
    content: '';
}
input[type=checkbox]:not(.old):checked + label > span:first-child:before,
input[type=radio]:not(.old):checked + label > span:first-child > span {
    display: block;
    width: .5em;
    height: .5em;
    margin: .125em;
    border: .0625em solid #052137;
    width: 9px;
    height: 9px;
    margin: 2px;
    border: 1px solid #052137;
    border-radius: 0;
    background: #052137;
}
.checkbox-valign + label {
    margin-top: 0;
}
@media (min-width: 768px) {
    .checkbox-valign + label {
        margin-top: 9px;
    }
}
